.main-adress {
    margin-top: 120px;
    margin-bottom: 118px;
    overflow: hidden;
    // background-color: #ffffff;
    // .parent-bg{
    //     background: #FFFFFF;
    //     box-shadow: 0px 10px 50px #0000000d;
    //     padding: 80px 116px 80px 116px;
    // }
    .main-card {
        // background: #111213;
        // border: #1e1f20;
        // border-radius: 8px;
        // padding: 30px;
        // gap: 25px;
    }
    .main-card {
        img {
            // margin-bottom: 30px;
            text-align: center;
        }
        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #151515;
            // margin-bottom: 20px;
            padding-top: 36px;
            padding-bottom: 4px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 171% */

            text-align: center;

            color: #393d40;
        }
    }
    .heading {
        margin-bottom: 50px;
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 45px;
            line-height: 110%;
            /* identical to box height, or 50px */

            text-align: center;

            color: #151515;
            padding-bottom: 32px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            /* or 21px */

            text-align: center;

            color: #393d40;
        }
    }
    .bgorange {
        background: #00000005;
        border: 1px solid #ffffff0d;
        border-radius: 24px;
        align-items: center;
        padding: 48px 0px;
        gap: 32px;
        text-align: center;
        &:hover {
            // box-shadow: 1px 4px 4px #cdb37f;
            // background: #d19722 !important;
        }
    }
    .bgblue {
        // background: #1777e2;
        // padding: 35px;
        // margin-right: 5px;
        // border: 1px solid #1777e2;
        // border-radius: 8px;
        // background: url("../../../Assests/Componentblue.png");
        //   background-position: center;
        //   background-size: cover;
        //   background-repeat: no-repeat;
        //   padding: 35px;
        //   margin-right: 5px;
        //   border-radius: 8px;

        &:hover {
            // box-shadow: 1px 4px 4px #146acc;
            // background: #1962b7 !important;
        }
    }
    .parent-img {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            margin-right: 31px;
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 50%;
            &:last-child {
                margin: 0 !important;
            }
        }
    }
}

@media (max-width: 991px) {
    .main-adress .parent-img {
        overflow-x: scroll;
        justify-content: flex-start;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .main-adress {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .main-adress .parent-bg {
        padding: 0 !important;
    }
}
@media (max-width: 1100px) {
    .main-adress .bgblue {
        margin-bottom: 5px;
        margin-right: unset;
        padding: 20px;
    }
    .main-adress .bgorange {
        padding: 20px;
        margin-bottom: 5px;
        margin-right: unset;
    }
}

@media (max-width: 991px) {
    .main-give .all-cards {
        flex-direction: column;
    }
    .main-give .line {
        display: none !important;
    }
    .main-give .all-cards .main-card {
        margin-bottom: unset;
        display: grid;
        justify-self: start;
        align-items: center;
        // flex-direction: column;
    }
    .main-give .all-cards .main-card p {
        text-align: start;
    }
}
@media (max-width: 600px) {
    .main-adress {
        // padding-top: 60px !important;
        // padding-bottom: 60px !important;
    }
    .main-adress .heading h2 {
        // margin-top: 40px;
        font-size: 28px !important;
        line-height: 30px;
    }
    .main-adress .heading {
        margin-bottom: 35px;
    }
    .main-adress .parent-bg {
        padding-bottom: 40px !important;
    }
    .main-adress .bgblue {
        // background: #1777e2;
        padding: 20px;
        margin-bottom: 5px;
        margin-right: unset;
    }
    .main-adress .bgorange {
        padding: 20px;
        margin-bottom: 5px;
        margin-right: unset;
    }

    .main-give .all-cards {
        margin-top: unset;
        // margin-top: 24px;
    }
    .main-give .all-cards {
        // padding: 35px 5px !important;
    }
    .main-adress .bgblue {
        // margin: 0 auto;
    }
    .main-give .all-cards .main-card {
        background: #111213;
        border-radius: 8px;
        padding: 20px;
        grid-gap: unset;
        gap: unset;
    }
    .main-adress .heading p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #bababa;
    }
}
