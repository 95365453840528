section.sec_section {
    margin-top: 46px;
    margin-bottom: 13px;
    overflow: hidden;
    .sendhanding {
        text-align: center;
        padding-bottom: 40px;
        h6.partners {
            text-transform: uppercase;
            color: #151515;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .slider_maindiv {
        margin-top: 123px;
        margin-bottom: 132px;
        button.ccupid {
            background: #fca5001a;
            border: 1px solid #fca5001a;
            border-radius: 5px;
            padding: 10px 20px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #db3435;
            margin-bottom: 32px;
            pointer-events: none !important;
        }
        h4.whatisupid {
            font-style: normal;
            font-weight: 700;
            font-size: 45px;
            line-height: 110%;
            color: #151515;
            margin-bottom: 32px;
        }
        p.revolutionary {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            color: #393d40;
            margin-bottom: 32px;
        }
        button.buyyy {
            background: #db3435;
            padding: 16px 32px;
            border-radius: 65px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            border: 2px solid #db3435;
        }
    }
    .commingsoom {
        // margin-top: 100px;
        // margin-bottom: 130px;
        text-align: center;
        h2.dating {
            font-style: normal;
            font-weight: 800;
            font-size: 70px;
            line-height: 110%;
            text-align: center;
            span.comingsoon\. {
                color: #db3435;
            }
        }
    }

    ul.slides {
        display: block;
        position: relative;
        height: 600px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        list-style: none;
    }

    .slides * {
        user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }

    ul.slides input {
        display: none;
    }

    .slide-container {
        display: block;
    }

    .slide-image {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
        transition: all 0.7s ease-in-out;
        max-height: 437px;
    }

    .slide-image img {
        width: auto;
        min-width: 100%;
        height: 100%;
    }

    .carousel-controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        font-size: 100px;
        line-height: 600px;
        color: #fff;
    }

    .carousel-controls label {
        display: none;
        position: absolute;
        padding: 0 20px;
        opacity: 0;
        transition: opacity 0.2s;
        cursor: pointer;
    }

    .slide-image:hover + .carousel-controls label {
        opacity: 0.5;
    }

    .carousel-controls label:hover {
        opacity: 1;
    }

    .carousel-controls .prev-slide {
        width: 49%;
        text-align: left;
        left: 0;
    }

    .carousel-controls .next-slide {
        width: 49%;
        text-align: right;
        right: 0;
    }

    .carousel-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 125px;
        z-index: 999;
        text-align: center;
    }

    .carousel-dots .carousel-dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #db3435;
        opacity: 0.5;
        margin: 10px;
        cursor: pointer;
    }

    input:checked + .slide-container .slide-image {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s ease-in-out;
    }

    input:checked + .slide-container .carousel-controls label {
        display: block;
    }

    input#img-1:checked ~ .carousel-dots label#img-dot-1,
    input#img-2:checked ~ .carousel-dots label#img-dot-2,
    input#img-3:checked ~ .carousel-dots label#img-dot-3,
    input#img-4:checked ~ .carousel-dots label#img-dot-4,
    input#img-5:checked ~ .carousel-dots label#img-dot-5,
    input#img-6:checked ~ .carousel-dots label#img-dot-6 {
        opacity: 5;
    }

    input:checked + .slide-container .nav label {
        display: block;
    }
}



.main_img {
    text-align: center;
}
@media (max-width: 1200px) {

    .col-xl-2.col-lg-2.col-md-6.col-sm-6.my-1.p-0-auto.padd-sm.aos-init.aos-animate {
        line-height: 52px;
  
    }
    .carouselll.aos-init.aos-animate {
        /* margin-top: 18px; */
        margin-bottom: -95px;
    }

}

@media (max-width: 450px) {
  
    .carouselll.aos-init.aos-animate {
        /* margin-top: 18px; */
        margin-bottom: -207px;
    }

    section.sec_section {
        text-align: center;
    }

    section.sec_section .slide-image {
        width: 100%;
        height: 46%;
    }
    section.sec_section .carousel-dots {
        bottom: 247px;
    }
    section.sec_section .slider_maindiv {
        margin-top: 60px;
        margin-bottom: 75px;
    }
}

@media (max-width:400px){
    video.banervideo {
        width: 314px;
        margin-top: -25px;
    }
    section.sec_section .slide-image img {
        width: auto;
        min-width: 100%;
        height: 89%;
    }
    // section.sec_section .slide-image {
    //     width: 100%;
    //     height: 40%;
    // }
}
// @media (max-width:2000px){
//     section.sec_section {

//         margin-top: 25rem;
//     }
// }
