.main-Building {
    padding-bottom: 196px;
    padding-top: 196px;
    background-color: #fff;
    .mainbuilding{
        margin-left: 120px;
        padding-right: 148px;

    }
    h2.allheading {
        font-style: normal;
        font-weight: 700 !important;
        font-size: 35px;
        line-height: 120%;
        color: #000A23;
        margin-bottom: 26px;
        margin-bottom: 28px;
  
    }
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #66718F;
      
    }
    .lastimmgg{
        text-align: end;
    }
}


@media(max-width:991px){
   .main-Building{
       padding-top: 100px !important;
       padding-bottom: 100px;
   }
   .main-Building h2.allheading{
    text-align: center;
    padding: 0 20px;
   }
   .main-Building p{
    text-align: center;
   }
   .main-Building .lastimmgg{
    text-align: center;
   }
}
@media(max-width:600px){
    .main-Building{
        padding-top: 60px !important;
    }
 }

@media (max-width:430px){
    h2{
        font-size: 30px !important;
        text-align: center;
    }
    .main-Building p{
        text-align: center;
    }
}