.secondlast {
  margin-top: 120px;
  margin-bottom: 100px;
  overflow: hidden;
  // .parentss {
  //   background: url("../../../Framesss.svg");
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  // }

  .main-bodyyy {
    background: #db3435;
    border-radius: 8px;
    border: 1px solid #db3435;

    max-height: 350px;
  }

  .parent {
    // padding: 236px 0;
    // padding: 54px;
    padding-left: 50px;
    h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 47px;
      line-height: 120%;
      /* or 56px */

      color: #ffffff;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      color: rgba(255, 255, 255, 0.7);
      margin-top: 15px;
      margin-bottom: 39px;
    }
    button {
      background: #ffffff;
      border-radius: 68px;
      padding: 16px 30px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      /* or 19px */
      border: 2px solid #db3435;
      color: #db3435;
      &:hover {
        color: #ffffff;
        background: #db3435;
        // border: 1px solid #2c2c2c;

        border: 2px solid #ffffff;
        border-radius: 68px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .desktop-img {
    position: unset;
 
    text-align: center;
}
  .secondlast .main-bodyyy {
    max-height: 100%;
  }
  .secondlast .parent {
    // padding-left: 50px;
    padding-top: 52px;
    padding-bottom: 48px;
  }
}

@media (max-width: 991px) {
  .secondlast .parent {
    // padding-top: 150px;
    // padding-bottom: 500px;
  }
}
@media (max-width: 780px) {
  .secondlast .parent {
    padding-top: unset;
  }
  .secondlast .parent {
    padding-left: unset;
    text-align: center;
  }
  .secondlast .parent h2 {
    line-height: 41px;

    padding-top: 40px;
  }
  .secondlast .parent p {
    margin-bottom: 40px;
  }
  .secondlast .parent button {
    margin-bottom: 55px;
  }
}

.desktop-img {
  
  text-align: center;
  .join-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}

@media (max-width: 600px) {
  .desktop-img {
    position: unset;
 
    text-align: center;
}
  .mobile-img {
    display: block !important;
    position: relative;
    z-index: 5;
    .join-shadow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }
}
