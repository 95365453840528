.main-button-mm.collapsed {
    .mmmm {
        display: none;
    }
    .mmm {
        display: block;
    }
}
.main-button-mm {
    .mmm {
        display: none;
        color: #000;
        span.plussss {
            color: #1777e2;
        }
    }
    .mmmm {
        display: block;
    }
}
.inner-main-right {
    .minus-sign {
        // font-size: 40px !important;
        color: #ffffff;
        font-weight: 300 !important;
    }
}

// .main-button-mm{
//     .mmm{
//         display: block;
//     }
// }
.highlightsxxxx {
    p.common.highlightsheading {
        // font-family: "Chakra Petch";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #1777E2;
        // margin-bottom: 20px;
    }
    h2.Pioneersss {
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 38px;
        /* identical to box height */

        text-align: center;

        color: #ffffff;
        padding-bottom: 22px;
    }
    p.faqs_para {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #a3a3a3;
    }
}
.commonnn {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #1777E2;
}

.main-faqs {
    /* padding-top: 71px; */
    // background: #1e1e1e;
    // padding-bottom: 20px;
    overflow: hidden;
    margin-top: 110px;
    .highlightsxxxx {
        text-align: center;

        // padding-top: 71px;
        padding-bottom: 45px;
    }
    .main-dd {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        width: 100%;
        .inner-main-left {
            display: flex;
            justify-content: start;
            // align-items: center;
            // padding: 30px 0px 10px 30px;
            .inner-left {
                padding-right: 10px;
                span {
                    // font-family: "Chakra Petch";
                    font-style: normal;
                    // font-weight: 600;
                    font-size: 18px !important;
                    line-height: 130%;
                    color: #1777E2;
                }
            }
            .inner-right {
                h5 {
                    // font-family: "Chakra Petch" !important;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 130%;
                    color: #000000;
                }
            }
        }
        .inner-main-right {
            padding-left: 10px;
            p {
                // font-family: "Chakra Petch";
                font-style: normal;
                // font-weight: 600;
                font-size: 30px !important;
                line-height: 130%;
                color: #1777E2;
            }
        }
    }
    .inner-upper {
        z-index: 150;
    }
    .ptb {
        padding: 80px 0 !important;
    }
    .inner-side {
        .card {
            background: transparent;
            // font-family: "Chakra Petch" !important;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 130%;
            color: #111216;
            // margin-bottom: 20px;
            border-bottom: 1px solid #000000 !important;
            margin-bottom: 5px;
            // border: aliceblue;
            // border: none !important;
            // border-style: solid;
            // border-color: #1777E2;
            &:first-child {
                // border-top: 2px solid #ffffff1a;
                // border-radius : 10px solid (90deg, #020024 0%, #ff00e0 100%, #00d4ff 100%);
            }
            // .card.gradient-box {
            //     border-style: solid;
            //     border-color: red #f01e32b3 green;
            // }
            .card-header {
                padding: 0px;
                // font-family: "Chakra Petch" !important;
                border-bottom: none;
                .btn {
                    color: #ffffff;
                    width: 100%;
                    text-align: left;
                    padding-left: 0;
                    white-space: unset !important;
                    text-decoration: none;
                    border-radius: 8px 8px 8px 8px;
                }
                .fa-plus,
                .fa-minus {
                    color: #f88d21;
                }
                h5 {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                }
            }
        }
    }
    .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
        // color: var(--bs-btn-active-color);
        // background-color: var(--bs-btn-active-bg);
        border-color: transparent !important;
    }
    .main-tile {
        margin-bottom: 10px;
        margin-top: 40px;
        // background: #f7f9fb;
        border: 2px solid #ffffff1a;
        box-sizing: border-box;
        // backdrop-filter: blur(50px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 10px;
        padding: 30px 20px;
        border: transparent;
        // height:301px;
        &:hover {
            // box-shadow: 5px 5px 30px #f07a3d33;
            border: 2px solid #ffffff1a;
            // border: 2px solid transparent;
        }
    }
    .inner-content {
        .svss {
            // font-family: "Chakra Petch" !important;
            font-style: normal;
            // font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #a3a3a3;
            padding-top: 15px;
            padding-bottom: 70.5px;
        }
        .shdbshb {
            // font-family: "Chakra Petch" !important;
            font-style: normal;
            // font-weight: 700;
            font-size: 40px;
            line-height: 110%;
            text-transform: uppercase;
            color: #000000;
        }
        img {
            border-radius: 10px;
        }
    }
    .inner-side .card .card-header {
        // background-color: #ffffff;
        // border: 0px 0px 38px 54px;
        // border-radius: 10px 10px 10px 10px;
        // font-family: "Chakra Petch" !important;
        h5 {
            // font-family: "Chakra Petch";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;

            color: #ffffff;
        }
        p {
            // font-family: "Gellix";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #dadff5;
        }
    }
    .card-body {
        // padding-top: 30px;
        /* padding-left: 67px; */
        /* padding-bottom: 30px; */
        /* padding-right: 35px; */
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        color: #bababa;
        /* background: beige; */
        // background: #111213;
        // border-width: 0px 1px 1px 1px;
        // border-style: solid;
        // border-color: #1e1f20;
        border-radius: 0px 0px 8px 8px;
        padding: 30px;

        background: #111213;
        border: 1px solid #1e1f20;
        // border-radius: 8px 8px 8px 8px;
    }
}
.main-faqs .inner-side .card .card-header .btn {
    padding: 0px;
}
.main-faqs .main-dd {
    // padding: 15px 0px;
}
.btn.focus,
.btn:focus {
    box-shadow: unset !important;
}
@media (max-width: 601px) {
    .main-faqs {
        padding-bottom: 0px;
    }
    .inner-side {
        .card {
            .card-header h5 {
                font-size: 14px;
            }
        }
    }
}

.card-header:first-child {
    border-radius: 0px !important;
}
.main-faqs .inner-side .card .card-header {
    // border-radius: 0px !important;
    // background-color: #faf6ff;
    // border-bottom: 1px solid #DADADA;
}

.card {
    // border-radius: 0px !important;
    // background-color: #faf6ff;
    // border-bottom: 1px solid #dadada;
}

.openclose .collapse.show {
    // background-color: #faf6ff;
    // border-bottom: 1.5px solid #dadada;
    // border-top: 0px;
}
.openclose .collapsing {
    // background-color: #730cf2 !important;
    border: none;
    border-top: 0px;
}
.main-button-mm {
    background-color: #111213 !important;
    // border: 0px 0px 38px 54px;
    // font-family: "Chakra Petch" !important;
    // padding: 35px 30px !important;
    padding-bottom: 20px !important;
    // font-family: "Chakra Petch" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 17px !important;

    color: #ffffff !important;
    // border-style: solid !important;
    // border-color: #1777E2 !important;
    // border-radius: 0px !important;
    // border-bottom: 0px;
    // border: 1px solid #1E1F20 !important;

    // border-radius: 8px 8px 0px 0px;

    padding: 27px 30px !important;
    background: #111213;
    border: 1px solid #1e1f20;
    border-radius: 8px 8px 0px 0px;
}

// .main-button-mm.collapsed {
//     background-color: #ffffff;

//     padding: 35px 30px;
//     background: transparent !important;
//     // font-family: "Chakra Petch" !important;
//     font-style: normal !important;
//     font-weight: 500 !important;
//     font-size: 20px !important;
//     line-height: 130% !important;
//     // border-color: #ffffff !important;
//     color: #111216 !important;
//     // border-style: solid !important;
//     border-radius: 0px !important;
// }

.card-body {
    transition: unset !important;
}
.collapse.show {
    transition: unset !important;
}

@media (max-width: 1100px) {
    .highlightsxxxx h2.Pioneersss {
        font-size: 28px !important;
        line-height: 30px;

        padding-bottom: 22px;
    }
}

@media (max-width: 780px) {
    .main-faqs .highlightsxxxx {
        text-align: center;
        padding-bottom: 35px;
    }
    .main-faqs .main-dd {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .main-faqs {
        margin-top: 0;
    }
}
