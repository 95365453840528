section.thrd_section {
    margin-top: 130px;
    overflow: hidden;
    background-image: url("../../../Assests/thrddbgimg.svg");
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    // position: relative;

    .tokenomicsthrdd {
        margin-top: 250px;
        // text-align: center;
        h1.tokenomics {
            text-align: center;
            text-transform: uppercase;
            background: linear-gradient(180deg, #ffffff 0%, #ffffff00 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            opacity: 0.5;
            font-style: normal;
            font-weight: 700;
            font-size: 75px;
            line-height: 110%;
            margin-bottom: 45px;
        }
    }
    .outer_card {
        background: linear-gradient(180deg, #ffffff0d 0%, #ffffff00 100%);
        backdrop-filter: blur(7.5px);
        border-radius: 15px;
        padding: 20px;
        .inner_card {
            background: linear-gradient(180deg, #ffffff14 -19.8%, #ffffff00 95.11%);
            backdrop-filter: blur(10px);
            border-radius: 12px;
            padding: 20px;
            h3.tokenomicsss {
                padding-bottom: 22px;
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 110%;
                color: #ffffff;
            }
            p.revolutionary {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 130%;
                color: #ffffffcc;
                padding-bottom: 22px;
            }
            ul.thrdbaner_list {
                padding-bottom: 79px;
                list-style: disc;
                padding-left: 20px;
                li {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 130%;
                    color: #ffffffcc;
                }
            }
        }
    }
    .chartimg {
        // text-align: center;
    }
    .lastthrd_texx {
        // text-align: center;
        // padding-bottom: 50px;
        h3.tokenomicsss {
            font-style: normal;
            font-weight: 800;
            font-size: 110px;
            line-height: 110%;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            opacity: 0.2;
        }
        h3.tokenomicsssnd {
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 110%;
            text-align: center;
            text-transform: uppercase;
            color: #ffffff;
            opacity: 0.2;
        }
    }
}
.lastthrd_texxOwnership {
    margin-bottom: 87px;
    text-align: center;
    h3.Ownership {
        font-style: normal;
        font-weight: 800;
        font-size: 65px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.2;
    }
}
.lastthrd_texxchart {
    padding-top: 98px;
    padding-bottom: 54px;
    text-align: center;
    h3.tokenomicsss_liquidity {
        font-style: normal;
        font-weight: 800;
        font-size: 65px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.2;
    }
    .lastthrd_texxchart h3.tokenomicsss_liquidity {
        font-style: normal;
        font-weight: 800;
        font-size: 65px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.2;
        padding-bottom: 10px;
    }
    h3.tokenomicsssndyears {
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.2;
        margin-top: 10px;
    }
}
@media (max-width: 450px) {
    section.thrd_section .tokenomicsthrdd {
        margin-top: 115px;
      
    }
    .lastthrd_texxOwnership h3.Ownership {
        font-size: 45px;
        line-height: 100%;
    }
}

@media (max-width: 1200px) {
section.thrd_section {
    background-image: url("../../../Assests/thrddbgimg.svg");
    background-size: contain;
    min-height: 100vh;
    background-repeat: no-repeat;
}
.mediaquery_tab {
    background: #DB3435;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
}
