.main-footer {
    // background: #0b0c22;
    // color: #dbdbdb;
    background: #f5f5f5;
    // border: 1px solid #1e1f20;
    // border-radius: 8px;
    .padng_left {
        padding-left: 20px;
        text-align: start;
    }
    .main_fotrdiv {
        background: #f5f5f5;
        border: 1px solid #dbdbdb;
        border-radius: 8px;
        padding: 34px 50px 34px 50px;
    }

    .yo {
        color: #dbdbdb !important;
        font-style: normal;
        font-weight: 400;
        font-size: 20px !important;
        line-height: 150% !important;

        opacity: 0.8;
        color: #dbdbdb;
    }

    .headdiinnngg {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 30px;
        color: #ffffff;
        margin-bottom: 15px;
    }

    .yoyonew {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #dbdbdb !important;
        margin-top: -20px;
    }
    .yoyonewwwwwww {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #bababa;
        margin-bottom: 12px;
        margin-top: 12px;
    }
    button.lastfotrbtn {

        background: #DB3435;
        border-radius: 48px;
        align-items: center;
        padding: 13px 37px;


  
        // width: 100%;
        border: 1px solid transparent;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        /* identical to box height, or 17px */
        
        
        color: #FFFFFF;
        &:hover {
            color: #DB3435;
            background: #ffffff;
            // box-shadow: 0px 4px 9px #1777e233;
            border-radius: 48px;
            border: 1px solid #DB3435;
        }
    }

    .w200 {
        width: 200px;
    }

    // input {
    //     // background: transparent;
    //     // box-shadow: 0px 0px 16px #00000040;
    //     // border-radius: 50px;
    //     // border: 1.5px solid #818283;
    //     padding: 18.5px 8px 18.5px 15px;
    //     background: #121213;
    //     border: 1px solid #2b2c2e;
    //     border-radius: 6px;
    //     margin-bottom: 12px;

    //     &::placeholder {

    //         background: #121213;
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 14px;
    //         line-height: 15px;

    //         color: #414141;

    //     }
    // }

    .picture {
        position: relative;

        img {
            position: absolute;
            top: 6px;
            right: 9px;
        }
    }
    .certerbdr {
        border: 1px solid #DDDDDD;
        transform: rotate(90deg);
        // margin-top: 73px;
        text-align: center;
    }
    // .input-group-text {
    //     background: #1fa5ff;
    //     color: #ffffff;
    //     box-shadow: 0px 0px 16px #00000040;
    //     border-radius: 50px;
    //     padding: 18.5px 8px 18.5px 15px;
    //     border: 1px solid #1fa5ff;
    //     position: absolute;
    //     right: 2px;
    //     top: 3px;
    //     // height: 45px;
    //     // width: 45px;
    // }

    .mt5 {
        margin-top: 50px;
    }

    .w28 {
        width: 28px;
    }

    li {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .inner-icon {
        p.yoyonewwwwwwww {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            /* identical to box height, or 21px */

            color: #151515;
            margin-bottom: 4px;
            margin-top: 30px;
        }
    }
    .form-control {
        background: #f5f5f5;
        border: 1px solid #dddddd;
        border-radius: 8px;
        padding: 14px 20px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #414141;
        // padding: 18.5px 8px 18.5px;

        margin-bottom: 20px;

        &:hover {
            border: 1px solid #ea3e3e;
            border-radius: 6px;
            // color: #ffffff;
        }
    }
    .form-control:focus {
        background: #f5f5f5;
        border: 1px solid #dddddd;
        border-radius: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #414141;
        // padding: 18.5px 8px 18.5px;
        padding: 14px 20px;
        &:hover {
            border: 1px solid #ea3e3e;
            border-radius: 6px;
            // color: #d62626;
        }
        &:active {
            border: 1px solid #ea3e3e;
        }
        &:focus {
            border: 1px solid #ea3e3e;
        }
    }
}
.col-xl-2.col-lg-2.col-md-6.col-sm-6.col-6.text_start {
    text-align: start;
}
.dignissimos {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    color: #bababa;
}

.headdiinnnggssss {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-transform: capitalize;

    color: #151515;
}

.yoyonewwwwwww {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    color: #bababa;
}

.rights {
    // background: #0b0c22;
    // color: #ffffff;
    // border-top: 1px solid #181936 !important;
    color: #54545466;
    background: #f5f5f5;
    margin-bottom: -24px;

    .ccvvxxopyright {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        /* border-top: 10px solid red; */
    }

    .cccopyright {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #dbdbdb;
    }

    p.text-dark.small.cccopyrighttttts.foterfst {
        text-align: start;
    }

    p.text-dark.small.cccopyrighttttts.fotersec {
        text-align: end;
        text-align: right;
    }
}

.footerhead {
    text-transform: capitalize;

    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 15px;
    /* identical to box height */

    color: #393d40 !important;
}

@media only screen and (max-width: 600px) {
    .inner-icon {
        text-align: start;
    }
    .rights {
        p.text-muted.small.cccopyrighttttts.foterfst {
            text-align: center !important;
        }

        p.text-muted.small.cccopyrighttttts.fotersec {
            text-align: center !important;
        }

        // background: #cf9600;
        text-align: center !important;

        .text-right {
            text-align: center !important;
        }
    }

    .main-footer {
        .headdiinnnggssss {
            margin-bottom: 15px !important;
            margin-top: 15px !important;
        }
    }

    .yoyonew {
        font-family: "Poppins" !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 24px !important;
        color: #dbdbdb !important;
    }

    .main-footer .headdiinnngg {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 27px;
        margin-bottom: 30px;
        color: #ffffff;
    }
}

@media screen and (max-width: 800px) {
    button.lastfotrbtn {
        width: 100%;
    }
    .inner-icon {
        text-align: center;
        margin-top: 25px;
    }
    .main-footer .padng_left {
        padding-left: 20px;
        text-align: center;
    }
    .inner-logo {
        text-align: center;
        margin-bottom: 29px;
    }
    .col-1.m-auto.padng_left.ddddnone {
        display: none;

    }

    .rights p.text-dark.small.cccopyrighttttts.foterfst {
        text-align: center;
    }

    .rights p.text-dark.small.cccopyrighttttts.fotersec {
        text-align: center;
    }
    .col-xl-2.col-lg-2.col-md-6.col-sm-6.col-6.text_start {
        text-align: start;
    }
    .main-footer .main_fotrdiv {
        padding: 15px 15px 18px 15px;
    }
}

@media screen and (max-width: 1024px) {
    .padng_left {
        padding-left: unset !important;
    }
    .col-1.padng_left.ddddnone {
        display: none;
    }
}

@media (max-width: 600px) {
    .main-footer .main_fotrdiv {
        background: unset;
        border: unset;
        border-radius: unset;
    }
    // .my-footer-border {
    //     background: #111213;
    //     border: 1px solid #1e1f20;
    //     border-radius: 8px;
    //     padding: 15px 15px 18px 15px;
    // }
}
