.apexcharts-menu.apexcharts-menu-open {
    opacity: 1;
    pointer-events: all;
    background: #4347b2;
    transition: 0.15s ease all;
    overflow: hidden;
}
// .apexcharts-toolbar {
//     display: none!important;
// }
div#apexchartsj6502yds {
    margin: 0 auto;
  }
  .apexcharts-canvas {
    position: relative;
    user-select: none;
    margin: 0 auto !important;
}
.stabl3graph{
    border-radius: 0 0 10px 10px !important;
}

.treasure-graph{
  height: 91.5% !important;

}
.treasure-graph svg{
  // margin-top: 80px;
}


//Guage Chart

.App-link {
    color: #61DAFB;
  }
  /* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  #gauge-chart6{
    height: 90% !important;
    background: rgba(19, 60, 168, 0.3);
    border: 1px solid #6F6ED4;
    backdrop-filter: blur(17px);
    border-radius: 0 0 10px 10px;
  }
  #gauge-chart6 svg{
    height: 100%;
  }

  #gauge-chart6 svg g .text-group text{
    transform: translateY(2.2in);
      font-weight: 600;
  }


  .apexcharts-tooltip {
    color: #fff !important;
  }

  @media (max-width:1199px){
    .treasure-graph svg{
      margin: 0 auto;
    }
  }

  @media (max-width:991px){
    #gauge-chart6{
      height: auto !important;
    }
  }
  @media (max-width:600px){
    #gauge-chart6 svg g .text-group text{
      transform: translate(0) !important;
    }
    // .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
    //   overflow: unset !important;

    // }
    .apexcharts-legend{
      overflow: unset !important;
      padding: 0 !important;
      justify-content: flex-start !important;
      align-items: center;
    }
  }.apexcharts-menu-icon {
    display: none!important;
}
.apexcharts-reset-icon {
    display: none!important;
}
.apexcharts-pan-icon {
    display: none!important;
}
.apexcharts-zoom-icon.apexcharts-selected {
    display: none!important;
}

#chart{
  #contianer:nth-child(1){
    // display: none;
  }
}


.tv-lightweight-charts{
  width: 100% !important;
}



.last-chart .apexcharts-canvas{
  margin: 0 auto !important;
}

.last-chart#chart{
  height: 85% !important;
}

@media (max-width:1199px){
  .lasttttt{
    margin-top: 20px;
  }
}