.main-banner {
    // // margin-top: 175px;
    // overflow: hidden;
    // background-image: url("../../../Assests/banarbg.svg");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    // // position: relative;
    // overflow: hidden;
    // position: relative;
    // min-height: 100vh;
    margin-top: -1px;
    background-color: #db3435;
    margin-bottom: 87px;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
    overflow: hidden;
    /* width: 1440px; */
    // height: 100vh;
    // min-height: 100vh;

    overflow: hidden;

    img.img-fluid.header_imgssss {
        position: absolute;
        // top: -88px;
        // right: -53px;
        // left: 0px;
    }

    .tomi-pink {
        color: #1777e2;
    }

    .inner-content.main-heading {
        margin-top: 67px;
        margin-bottom: 40px;
        p.pprotocol {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 110%;
            /* or 55px */

            color: #ffffff;
            padding-bottom: 32px;
        }
        h3 {
            font-family: "Archivo", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 120%;
            text-align: center;
            color: #121212;
        }
        h2.parafirstcc {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 51px;
            line-height: 110%;
            /* or 55px */

            color: #ffffff;
        }
        p.parafirstff {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            /* or 21px */

            color: #ffffffb3;
            padding-top: 32px;
            padding-bottom: 50px;
        }
        button.btn.button-hedaerss {
            background: #ffffff;
            border-radius: 45px;
            padding: 16px 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height */
            border: 2px solid #db3435;

            color: #db3435;
            margin-right: 12px;
            &:hover {
                background: transparent;
                border: 2px solid #f1f1f1;
                color: #ffffff;

                // box-shadow: 0px 4px 9px #1777e233;
            }
        }
        button.btn.button-hedaersss {
            background: #db3435;
            border: 1.5px solid #ffffff;
            border-radius: 45px;
            padding: 16px 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height */

            color: #ffffff;
            &:hover {
                background: #ffffff;
                border: 1.5px solid #ffffff;
                color: #db3435;
            }
        }
    }

    .lovessssssimg {
        text-align: center;
        margin-top: 80px;
        padding-bottom: 30px;
        img.img-fluid.header_imgsss {
            width: 150px;
            transform: rotate(591deg);
        }
    }
    .modal-content {
        border-radius: 20px;
        .for-padding {
            padding: 0 50px;
        }
        .form-group {
            input {
                background: #ffffff;
                border: 1px solid #d4d4d4;
                box-sizing: border-box;
                border-radius: 10px;
                height: 45px;
                padding: 6px 12px;
            }
        }
        .btn-common {
            width: 100%;
        }
    }
}

.counter {
    background: #fff;
}
.cursor-pointer {
    cursor: pointer;
}
@media (max-width: 991px) {
    .main-banner .input-field {
        width: 100%;
    }
    .main-banner .inner-content.main-heading.text-center h3 {
        font-size: 45px !important;
    }
}
@media only screen and (max-width: 600px) {
    .main-banner {
        .w-50 {
            width: 100% !important;
        }

        .input-group-text {
            background: #1777e2;
            color: #ffffff;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
            border-radius: 0px 20px 20px 0px;
            padding: 10px 10px;
        }
    }
    .main-banner .parafirst p {
        margin: unset !important;
        margin-bottom: 56px !important;
        font-size: 15px !important;
    }
}

.abb {
    outline: none !important;
    box-shadow: none !important;
}

@media (max-width: 600px) {
    .main-banner .input-field .btn-searchbar {
        position: unset !important;
        margin-top: 20px;
        width: 100%;
    }
    .main-banner {
        // padding-bottom: 39px;
    }
    .lastbtnbnr {
        justify-content: space-between;
        max-width: 269px;
        margin: 0 auto;
    }
}

@media (max-width: 430px) {
    .main-banner .input-field input {
        padding-left: 50px;
    }
    .main-banner .inner-content.main-heading.text-center h3 {
        font-size: 26px !important;
    }
    .main-banner .inner-content.main-heading.text-center h3 {
        font-size: 24px !important;
    }
}

@media (max-width: 2500px) {
    img.img-fluid.header_img {
        margin-right: -167px;
    }
}

@media (max-width: 1199px) {
    .main-banner {
        margin-top: -1px;

        height: unset;
        min-height: 100vh;
    }
    .main-banner .inner-content.main-heading {
        margin-top: 5px;
        margin-bottom: unset;
    }
    video.banervideo {
        width: 650px;
        margin-top: 20px;
        /* border: none; */
    }
}

@media (max-width: 1510px) {
    .main-banner {
        margin-top: -41px;
        background-color: #db3435;
        /* margin-bottom: 87px; */
        // clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
        /* width: 1440px; */
        /* height: 100vh; */
        /* min-height: 100vh; */
        overflow: hidden;
        min-height: unset;
    }
}
@media (max-width: 800px) {
    .main-banner .lovessssssimg {
        text-align: center;
        margin-top: 92px;
    }

    .main-banner {
        clip-path: polygon(0 0, 100% 0, 100% 82%, 50% 100%, 0 82%);
    }
    .main-banner {
        height: 122vh;
        // width: unset;
        // margin-top: unset;
    }
    video.banervideo {
        width: 615px;
        margin-top: 4px;
    }

    img.img-fluid.header_img {
        margin-right: unset;
        margin-top: -82px;
        margin-bottom: -100px;
    }
    .inner-content.main-heading {
        text-align: center;
    }
    .main-banner .inner-content.main-heading p.pprotocol {
        font-size: 14px;
        line-height: 15px;

        padding-bottom: 20px;
    }
    .main-banner .inner-content.main-heading h2.parafirstcc {
        font-size: 35px;
    }
    .main-banner .inner-content.main-heading p.parafirstff {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .lastbtnbnr {
        display: flex;
        align-items: center;
        margin-bottom: 42px;
        justify-content: center;
    }
    .main-banner .inner-content.main-heading button.btn.button-hedaerss {
        padding: auto;

        font-size: 14px;
        line-height: 100%;
    }
    .main-banner .inner-content.main-heading button.btn.button-hedaersss {
        // padding: 12px 20px;

        font-size: 14px;
        line-height: 100%;
    }
}

@media (max-width: 550px){
video.banervideo {
    width: 398px;
    margin-top: 4px;}
}
@media (max-width: 450px) {
    .main-banner .lovessssssimg img.img-fluid.header_imgsss {

        transform: rotate(591deg);
    }
    .main-banner .lovessssssimg {
        text-align: center;

        margin-top: 20px !important;
        width: 60px !important;
    }
    .main-banner .inner-content.main-heading {
        margin-top: 0px;
        margin-bottom: unset;
    }
    .main-banner {
        clip-path: polygon(0 0, 100% 0, 100% 90%, 53% 100%, 0 90%);

        margin-top: -40px;

        height: 80vh;
    }
    .main-banner .inner-content.main-heading p.parafirstff {
        margin-top: 5px;
        margin-bottom: 25px;
        padding-top: unset;
        padding-bottom: unset;
    }

    video.banervideo {
        width: 300px;
        margin-top: 0px;
    }
    .main-banner .lovessssssimg {
        text-align: center;
        margin-top: 60px;
    }
}

.bg-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
