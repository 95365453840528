.roadmap {
    margin-top: 140px;
    overflow: hidden;
    margin-bottom: 120px;
    body {
        // font-family: "Chakra Petch", sans-serif !important;
    }

    .timeline__heading {
        font-style: normal;
        font-weight: 700;
        font-size: 45px;
        line-height: 110%;
        /* identical to box height, or 50px */

        text-align: center;

        color: #151515;
    }
    p.inventorexx {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        /* or 21px */

        text-align: center;

        color: #393d40;
        padding-top: 32px;
        padding-bottom: 50px;
    }
    .timeline__sections {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        width: 100%;
        // max-width: 8000px;
        transition: width 1s;
        overflow-x: auto;
        gap: 10px;
        padding-bottom: 38px;
        &::-webkit-scrollbar {
            display: none !important;
        }

        .roadmap_cards {
            background: linear-gradient(180deg, #0000000d 0%, #f9fafc0d 100%);
            box-shadow: 0px 11px 30px -20px #15151530;
            border-radius: 15px;
            border: 2px solid #f9fafc;
            // padding-left: 30px;
            // padding-right: 34px;
            // width: 394px;
            flex: 1 0 30%;
            padding: 72px 30px 34px 54px;
            width: 394.67px;
            height: 400px;
            //         padding-top: 72px;
            // padding-bottom: 54px;
            p.card_date {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 110%;
                color: #1515154d;
                padding-top: 50px;
                padding-bottom: 4px;
            }
            h3.quarter {
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 110%;
                text-transform: uppercase;
                color: #151515;
                padding-bottom: 50px;
            }
            ul.roadmap_list {
                margin-left: 16px;
                list-style: disc !important;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 130%;
                color: #393d40;
            }
        }
    }

    .timeline__text {
        color: #000000;
        margin-left: 18px;
        list-style: disc !important;
        // font-family: "Chakra Petch"!important;
        ::marker {
            color: #1777e2;
        }
        .liiisss {
            margin-bottom: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            /* identical to box height, or 21px */

            color: #ffffff;
        }
    }

    .entry:nth-child(even) .time {
        transform: translate(40%, -48%);
    }

    @media only screen and (max-width: 600px) {
        .roadmap {
            margin-top: unset;
        }

        .container {
            margin: 0;
        }

        .entry:nth-child(even) .content,
        .entry:nth-child(odd) .content {
            text-align: left;
            transform: translate(60%, -9px);
        }

        .entry:nth-child(even) .time,
        .entry:nth-child(odd) .time {
            transform: translate(-130%, -48%);
        }
    }
}

@media (max-width: 600px) {
    .roadmap {
        margin-top: unset;
    }
    .roadmap .timeline__heading {
        // margin-top: 84px !important;
    }
    .roadmap .timeline__title {
        text-align: start;
    }
}
@media (max-width: 800px) {
    .roadmap {
        margin-top: unset;
    }
}

@media (max-width: 991px) {
    .roadmap .timeline__sections .roadmap_cards {
        flex: 1 0 49% !important;
    }
}

@media (max-width: 600px) {
    .roadmap .timeline__sections .roadmap_cards {
        flex: 1 0 90% !important;
    }
    .roadmap .timeline__sections {
        margin-left: 0 !important;
    }
}
