.main-Computerb {
    // background-color: #FFFFFF;
    // padding-top: 110px;
    overflow: hidden;
    .aboutusimgg {
        position: relative;
        img.img-fluid.left-img.aaaboutus_imgs {
            position: absolute;
            top: 0;
            right: 0px;
            left: 33px;
            width: 85%;
        }
    }

    .right {
        p.aboutvv {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            text-transform: uppercase;

            /* Primary Blue */

            color: #1777e2;
            padding-bottom: 20px;
        }
        h2.sit {
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 38px;
            color: #ffffff;
            padding-bottom: 22px;
        }
        h5.tempore {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 27px;
            color: #ffffff;
            padding-bottom: 22px;
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 120%;
            color: #000a23;
            margin-bottom: 28px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            /* or 21px */

            color: #bababa;
        }
    }
}

@media (max-width: 991px) {
    .main-Computerb .order {
        order: 2 !important;
        text-align: center;
        margin-top: 40px;
    }
    .main-Computerb .right h2 {
        text-align: center;
        // padding: 0 20px;
    }
    .main-Computerb .right p {
        text-align: center;
    }
    .main-Computerb {
        padding-top: unset;
    }
}
@media (max-width: 600px) {
    .main-Computerb .right h5.tempore {
  
        text-align: start;
    }
    .main-Computerb .right p.aboutvv {
        margin-top: unset;
    }
    .main-Computerb .right h2 {
        text-align: start;
        font-size: 28px !important;
        line-height: 30px;
        padding-bottom: 22px;
        margin-bottom: unset !important;
    }

    .main-Computerb .right h5.tempore {
        font-size: 20px;
        line-height: 22px;
    }
}

@media (max-width: 430px) {
    .main-Computerb .order {
        order: 2 !important;
    }
    .main-Computerb .right {
        padding-bottom: unset;
    }
    .main-Computerb .right p {
        text-align: start;
    }
}

@media (max-width: 1030px) {
    .main-Computerb .right p.aboutvv {
        margin-top: 152px;
    }
}

@media (max-width: 800px) {
    .main-Computerb .right h5.tempore {
  
        // text-align: center;
    }
    .main-Computerb .right p.aboutvv {
        margin-top: unset;
    }
}
