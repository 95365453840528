.main-give {
    // background-color: #FFFFFF;
    margin-top: 140px;
    overflow: hidden;

    .seccard_hadng {
        text-align: center;
        margin-bottom: 45px;
        h3.cchoose {
            font-family: "Archivo";
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 38px;
            text-align: center;
            color: #ffffff;
            padding-bottom: 22px;
        }
        p.accusantium {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            text-align: center;
            color: #bababa;
        }
    }

    .all-cards {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 40px;
        gap: 5px;
        // background: #FFFFFF;
        // box-shadow: 0px 10px 50px #0000000d;
        // margin-top: -150px;
        
        .main-card {
            background: #111213;
            border-radius: 8px;
            padding: 30px;
            gap: 25px;
            background: #111213;
            border: 1px solid #1e1f20;
            border-radius: 8px;
            &:hover {
      
                // box-shadow: 1px 4px 4px #856c6c40;
            
       
              }
        }
        .main-card {
            img {
                margin-bottom: 30px;
            }
            h6 {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 22px;

                color: #ffffff;
                margin-bottom: 20px;
            }
            p {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                /* or 22px */

                color: #bababa;
            }
        }
        .line {
            margin: 0 55px;
        }
    }
}

@media (max-width: 991px) {
    .main-give {
        margin-top: 70px;
    }
    .main-give .all-cards {
        flex-direction: column;
    }
    .main-give .line {
        display: none !important;
    }
    .main-give .all-cards .main-card {
        // margin-bottom: 30px;
        // display: flex;
        // justify-self: center;
        // align-items: center;
        // flex-direction: column;
    }
    .main-give .all-cards .main-card p {
        text-align: center;
    }
}
@media (max-width: 600px) {
    .main-give .all-cards {
        margin-top: unset;
        margin-top: 24px;
    }
    .main-give .all-cards {
        padding: 40px 16px !important;
    }
    .main-give .seccard_hadng h3.cchoose {
        font-size: 28px;
        line-height: 30px;

        padding-bottom: 22px;
    }
    
}
